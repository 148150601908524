<template>
  <div class="marginTop20 textAlign">
    <el-button
      v-if="showTime"
      type="primary"
      @click="handleCheckStart"
    >进行检测</el-button>
    <el-button
      v-else
      type="primary"
      @click="handleCheckEnd"
    >停止检测</el-button>
    <el-button
      v-if="showTime"
      type="primary"
      @click="handleExport"
    >导出数据</el-button>
  </div>
</template>

<script>
import qs from "qs";
export default {
  name: "index",
  props: {
    id: {
      typeof: String,
    },
  },
  data() {
    return {
      showTime:true, //显示开始或者结束
      createTimeEnd:'', //检查结束时间
      createTimeStart:'', //检测开始时间
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (
      searchForm?.path === this.$route.path &&
      searchForm?.name === "createFive"
    ) {
      this.searchData = {};
      if (
        searchForm.searchData?.createTimeStart &&
        searchForm.searchData?.createTimeEnd
      ) {
        this.searchData.createTime = [
          searchForm.searchData?.createTimeStart,
          searchForm.searchData?.createTimeEnd,
        ];
      }
    } else {
      localStorage.removeItem("searchForm");
    }
  },
  methods: {
       // 检测 开始
       handleCheckStart() {
      this.showTime = false
      detectionStart(this.id).then((res) => {
        if (res.code === 200) {
        } else {
          this.$message.error(res.msg);
        }
      });
      this.createTimeStart = dayjs().format('YYYY-MM-DD HH:mm:ss')
    },
    // 检测 结束
    handleCheckEnd() {
      this.showTime = true
      detectionEnd(this.id).then((res) => {
        if (res.code === 200) {
        } else {
          this.$message.error(res.msg);
        }
      });
      this.createTimeEnd = dayjs().format('YYYY-MM-DD HH:mm:ss')
    },
    // 导出
    handleExport() {
      if(this.createTimeStart == '' || this.createTimeEnd == '') {
        this.$message.warning("请先进行检测");
      } else {
        const searchData = {
          id: this.id,
          createTimeStart: this.createTimeStart,
          createTimeEnd: this.createTimeEnd,
        };

        window.open(
          `/api/web/car/manage/detail/detection/export?${qs.stringify(searchData)}`,
          "_self"
        );
      }

    },
  },
};
</script>

<style scoped>
</style>