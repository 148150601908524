<template>
  <div>
    <h4 class="marginBottom20 title-18">基础信息</h4>
    <div class="flex">
      <div class="width150">
        <el-image
            style="width: 100px; height: 100px"
            :preview-src-list="[item]"
            :src="form.image"
            >
          </el-image>
        <!-- <img
          :src="form.image"
          alt=""
          width="120px"
          height="120px"
        > -->
      </div>
      <div>
        <div class="flex marginBottom10">
          <div class="width150">车辆编号: </div>
          <div>{{form.code}}</div>
        </div>
        <div class="flex marginBottom10">
          <div class="width150">TBOX: </div>
          <div>{{form.tbox}}</div>
        </div>
        <div class="flex marginBottom10">
          <div class="width150">当前电量(%): </div>
          <div>{{form.currentPower}}</div>
        </div>
        <div class="flex marginBottom10">
          <div class="width150">TBOX当前电压(V): </div>
          <div>{{form.tboxCurrentVoltage}}</div>
        </div>
        <div class="flex marginBottom10">
          <div class="width150">车辆当前电压(V): </div>
          <div>{{form.currentVoltage}}</div>
        </div>
        <div class="flex marginBottom10">
          <div class="width150">车辆备用电压(V): </div>
          <div>{{form.reserveVoltage}}</div>
        </div>
        <div class="flex marginBottom10">
          <div class="width150">运行状态: </div>
          <div>{{form.moveState===1?'可用':form.moveState===2?'被预约':form.moveState===3?'使用中':form.moveState===4?'用户上报故障':form.moveState===5?'系统自测故障':''}}</div>
        </div>
        <div class="flex marginBottom10">
          <div class="width150">信号状态: </div>
          <div>{{form.signalState}}</div>
        </div>
        <div class="flex marginBottom10">
          <div class="width150">数据更新时间: </div>
          <div>{{form.createTime}}</div>
        </div>
        <div class="flex marginBottom10">
          <div class="width150">车型名称: </div>
          <div>{{form.carTypeName}}</div>
        </div>
        <div class="flex marginBottom10">
          <div class="width150">所需押金(元): </div>
          <div>{{form.depositPrice}}</div>
        </div>
        <div class="flex marginBottom10">
          <div class="width150">低档速度(km/h): </div>
          <div>{{form.lowGearSpeed}}</div>
        </div>
        <div class="flex marginBottom10">
          <div class="width150">高档速度(km/h): </div>
          <div>{{form.highGearSpeed}}</div>
        </div>
        <div class="flex marginBottom10">
          <div class="width150">添加时间: </div>
          <div>{{form.createTime}}</div>
        </div>
        <div class="flex marginBottom10">
          <div class="width150">启动状态: </div>
          <div>
            <el-switch
              v-if="form.moveState !== 3"
              v-model="form.startState"
              :inactive-value="2"
              :active-value="1"
              @change="handleSwitchStart($event,id)"
            ></el-switch>
            <span v-else>{{ form.startState === 1?'开':form.startState === 2?'关':'' }}</span>
          </div>
        </div>
        <div class="flex marginBottom10">
          <div class="width150">休眠状态: </div>
          <div>
            <el-switch
              v-if="form.moveState !== 3"
              v-model="form.dormancyState"
              :inactive-value="2"
              :active-value="1"
              @change="handleSwitchDormancy($event,id)"
            ></el-switch>
            <span v-else>{{ form.dormancyState === 1?'开':form.dormancyState === 2?'关':'' }}</span>
          </div>
        </div>
        <div class="flex marginBottom10">
          <div class="width150">启用状态: </div>
          <div>
            <el-switch
              v-if="form.moveState !== 3 && form.moveState !== 4 && form.moveState !== 5"
              v-model="form.state"
              :inactive-value="2"
              :active-value="1"
              @change="handleSwitchState($event,id)"
            ></el-switch>
            <span v-else>{{ form.state === 1?'开':form.state === 2?'关':'' }}</span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {
  detailBase,
  updateStart,
  updateDormancy,
  updateState,
} from "@/api/car/manage";
export default {
  props: {
    id: {
      typeof: String,
    },
  },
  data() {
    return {
      form: {
        carTypeName: "",
        code: "",
        createTime: "",
        currentPower: "",
        currentVoltage: "",
        depositPrice: "",
        dormancyState: "",
        highGearSpeed: "0",
        id: "",
        image: "",
        lowGearSpeed: "",
        moveState: "",
        regionName: "",
        reserveVoltage: "",
        signalState: "",
        startState: "",
        state: "",
        tbox: "",
        tboxCurrentVoltage: "",
      },
    };
  },
  mounted() {
    console;
    if (this.id) {
      this.getView();
    }
  },
  methods: {
    onCreateOneHandle() {
      this.$emit("onCreateOneHandle", this.form);
    },
    async getView() {
      const { data } = await detailBase({ id: this.id });
      this.form = data;
      this.forms = this.form;
      this.onCreateOneHandle();
    },
    handleSwitchStart(e, id) {
      // console.log(e,id)
      updateStart({
        id,
        startState: e,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("操作成功");
          this.getView();
        } else {
          this.$message.error(res.msg);
        }
        this.onLoad();
      });
    },
    handleSwitchDormancy(e, id) {
      // console.log(e,id)
      updateDormancy({
        id,
        dormancyState: e,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("操作成功");
          this.getView();
        } else {
          this.$message.error(res.msg);
        }
        this.onLoad();
      });
    },
    handleSwitchState(e, id) {
      // console.log(e,id)
      updateState({
        id,
        state: e,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("操作成功");
          this.getView();
        } else {
          this.$message.error(res.msg);
        }
        this.onLoad();
      });
    },
  },
};
</script>

<style>
</style>