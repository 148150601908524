<template>
  <div>
    <h3>车辆管理</h3>

    <avue-crud
      :data="dataFive"
      :option="option"
      :table-loading="showLoading"
    >

      <template
        slot="menu"
        slot-scope="{ row }"
      >
      <el-button
          type="text"
          size="mini"
          @click="handleDetails1(row)"
        >查看详情</el-button>
        <el-button
          type="text"
          size="mini"
          @click="handleDelete(row)"
        >删除</el-button>
      </template>
    </avue-crud>
    <el-dialog
      title="提示"
      width="80%"
      :visible.sync="dialogVisible"
      :before-close="handleClose">

        <dialogCar :ids="detailsId"  />

    </el-dialog>
 
  </div>

</template>

<script>
import dialogCar from './dialogCar';
export default {
  name: "index",
  components:{
    dialogCar,
  },
  props: {
    dataFour: {
      type: Object,
      default: () => {},
    },
    dataFive: {
      type: Array,
    },
  },
  data() {
    return {
      showLoading: false,
      dialogVisible: false,
      detailsId:'',
      option: {
        width: 3000,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: false, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "车型名称",
            prop: "typeName",
          },
          {
            label: "车辆编号",
            prop: "code",
          },
          {
            label: "车辆二维码",
            prop: "image",
            type: "img",
          },
        ],
      },
    };
  },
  methods: {
    handleClose(done) {
        done();
      },
    onDataFourHandle() {
      const data = {
        dataFour: this.dataFour,
        dataFive: this.dataFive,
      };

      this.$emit("onDataFourHandle", data);
    },
      // 查看详情
      handleDetails1(row) {
        this.dialogVisible = true
        this.detailsId = row.id
      // this.$router.push({
      //   path: "/car/manage/details",
      //   query: {
      //     type: "details",
      //     id: row.id,
      //   },
      // });
    },
    // 删除
    handleDelete(row) {
      console.log("删除", row);
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.dataFive = this.dataFive.filter((item) => item.id !== row.id);
        this.dataFour.carTypes = this.dataFour.carTypes.map((item) => {
          const carId = item?.carId.filter((items) => items.id !== row.id);
          return {
            ...item,
            carId,
          };
        });

        this.onDataFourHandle();
      });
    },
  },
};
</script>

<style scoped>
</style>