<template>
	<div>
		<h3>车型管理</h3>
		<avue-form ref="form" v-model="form" :option="formOption">
			<template slot="carTypes">
				<div class="main-content">
					<avue-crud :data="dataFour.carTypes" :option="option" :table-loading="showLoading">
						<template #header>
							<div class="flex align-items marginBottom20">
								<el-button size="medium" type="primary" icon="el-icon-plus"
									@click="handleCreate">添加车型</el-button>
							</div>
						</template>

						<template slot="bufferNum" slot-scope="{ row }">
							<el-input v-model="row.bufferNum" :value="row.bufferNum" placeholder="请输入"
								onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
								@blur="salaryChange($event,'bufferNum',999999,row.$index)" type="text" clearable />
						</template>

						<template slot="orderFirstRemind" slot-scope="{ row }">
							<el-input v-model="row.orderFirstRemind" :value="row.orderFirstRemind" placeholder="请输入"
								onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
								@blur="salaryChange($event,'orderFirstRemind',999999,row.$index)" type="text"
								clearable />
						</template>

						<template slot="orderTwoRemind" slot-scope="{ row }">
							<el-input v-model="row.orderTwoRemind" :value="row.orderTwoRemind" placeholder="请输入"
								onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
								@blur="salaryChange($event,'orderTwoRemind',row.orderFirstRemind,row.$index)"
								type="text" clearable />
						</template>

						<template slot="lowBatteryRemind" slot-scope="{ row }">
							<el-input v-model="row.lowBatteryRemind" :value="row.lowBatteryRemind" placeholder="请输入"
								onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
								@blur="salaryChange($event,'lowBatteryRemind',100,row.$index)" type="text" clearable />
						</template>

						<template slot="lowBatteryNoUse" slot-scope="{ row }">
							<el-input v-model="row.lowBatteryNoUse" :value="row.lowBatteryNoUse" placeholder="请输入"
								onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
								@blur="salaryChange($event,'lowBatteryNoUse',999999,row.$index)" type="text"
								clearable />
						</template>

						<template slot="lockTime" slot-scope="{ row }">
							<el-input v-model="row.lockTime" :value="row.lockTime" placeholder="请输入"
								onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
								@blur="salaryChange($event,'lockTime',999999,row.$index)" type="text" clearable />
						</template>

						<template slot="workerMoveCar" slot-scope="{ row }">
							<el-input v-model="row.workerMoveCar" :value="row.workerMoveCar" placeholder="请输入"
								onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
								@blur="salaryChange($event,'workerMoveCar',100,row.$index)" type="text" clearable />
						</template>

						<template slot="dayUseTimeSlotEnd" slot-scope="{ row }">
							<div class="flex">
								<div class="width200">
									<el-time-select class="width200" placeholder="起始时间"
										v-model="row.dayUseTimeSlotStart" :value="row.dayUseTimeSlotStart"
										:picker-options="{
     start: '00:00',
     step:'00:01',
     end: '23:58',
     maxTime: row.dayUseTimeSlotEnd
    }">
									</el-time-select>
								</div>

								<div style="margin:0 10px">
									-
								</div>
								<div class="width200">
									<el-time-select class="width200" placeholder="结束时间" v-model="row.dayUseTimeSlotEnd"
										:value="row.dayUseTimeSlotEnd" :picker-options="{
      start: '00:00',
      step:'00:01',
      end: '23:59',
      minTime: row.dayUseTimeSlotStart
    }">
									</el-time-select>
								</div>
							</div>
						</template>

						<template slot="menu" slot-scope="{ row }">
							<el-button type="text" size="mini" @click="handleCreateCarList(row)">添加车辆</el-button>
							<el-button type="text" size="mini"
								@click="handleFreeze(row)">{{ row.state==1?'冻结':row.state==2?'解冻':'' }}</el-button>

							<el-button type="text" size="mini" @click="handleDelete(row)">删除</el-button>
						</template>
					</avue-crud>

				</div>
			</template>

		</avue-form>

		<CarType :show="dialogVisibleCarType" :defaultData="carTypes" @change="handleSelectData"
			@newchange="handleSelectNewData" @close="handleSelectClose" />

		<CreateCar :show="dialogVisibleCarList" :defaultData="carLists" @change="handleSelectDataCarList"
			@newchange="handleSelectNewDataCarList" @close="handleSelectCloseCarList" :carId="carId" />

	</div>

</template>

<script>
	import CarType from "./carType/index.vue";
	import CreateCar from "./createCar/index.vue";

	export default {
		name: "index",
		props: {
			dataFour: {
				type: Object,
				default: () => {},
			},
			dataFive: {
				type: Array,
			},
		},
		components: {
			CarType,
			CreateCar,
		},
		data() {
			return {
				tableData: [],
				showLoading: false,
				formOption: {
					submitBtn: false,
					emptyBtn: false,
					labelWidth: 0,
					column: [{
						label: "",
						prop: "carTypes",
						maxlength: 20,
						showWordLimit: true,
						span: 24,
						row: true,
						// rules: [
						//   {
						//     required: true,
						//     message: "请选择车辆",
						//     trigger: "blur",
						//   },
						// ],
					}, ],
				},
				option: {
					width: 2300,
					header: false,
					stripe: false, // 显示斑马纹
					showHeader: true, // 显示表头
					index: false, // 显示索引
					indexLabel: "序号", // 索引文字
					size: "small",
					selection: false, // 显示多选框
					page: false, // 显示分页
					align: "center",
					menuAlign: "center",
					menuType: "text",
					editBtn: false,
					delBtn: false,
					column: [{
							label: "车型编号",
							prop: "code",
							width: 200,
						},
						{
							label: "车型名称",
							prop: "name",
							width: 200,
						},
						{
							label: "速度档位",
							prop: "gearSpeed",
							width: 100,
							formatter: function(row, column, cellValue) {
								return cellValue == '1' ? "低档" : cellValue == '2' ? "高档" : "";
							},
						},
						{
							label: "档位速度(km/h)",
							prop: "gearSpeed",
							width: 200,
							formatter: function(row, column, cellValue) {
								return cellValue == '1' ?
									row.lowGearSpeed :
									cellValue == '2' ?
									row.highGearSpeed :
									"";
							},
						},
						{
							label: "电子围栏缓冲区（米）",
							prop: "bufferNum",
							width: 100,
						},
						{
							label: "第一次订单倒计时提醒设置（分钟）",
							prop: "orderFirstRemind",
							width: 150,
						},
						{
							label: "第二次订单倒计时提醒设置（分钟）",
							prop: "orderTwoRemind",
							width: 300,
						},
						{
							label: "低电量提醒（%）",
							prop: "lowBatteryRemind",
							width: 200,
						},
						{
							label: "低电量无法解锁使用（%）",
							prop: "lowBatteryNoUse",
							width: 200,
						},
						{
							label: "预约呼叫锁定时间（分钟）",
							prop: "lockTime",
							width: 200,
						},
						{
							label: "工作人员挪车时间（分钟）",
							prop: "workerMoveCar",
							width: 200,
						},
						{
							label: "每日可用时间段",
							prop: "dayUseTimeSlotEnd",
							width: 300,
						},
						{
							label: "已有车辆",
							prop: "carNum",
							width: 100,
							formatter: function(row, column, cellValue) {
								return row?.carId?.length > 0 ? row?.carId?.length : '';
							},
						},
						{
							label: "状态",
							prop: "state",
							width: 100,
							formatter: function(row, column, cellValue) {
								return cellValue == '1' ? "正常" : cellValue == '2' ? "冻结" : "";
							},
						},
					],
				},
				dialogVisibleCarType: false,
				dialogVisibleCarList: false,
				form: {
					carTypes: [],
				},
				carTypes: [],
				newCarTypes: [],
				carId: "",
				carList: [],
				carLists: [],
			};
		},
		created() {
		},
		methods: {
			onDataFourHandle() {
				const data = {
					dataFour: this.form,
					dataFive: this.dataFive,
				};

				this.$emit("onDataFourHandle", data);
			},
			// 添加
			handleCreate() {
				this.newCarTypes = [...this.form.carTypes];
				this.carTypes = this.form.carTypes;
				this.dialogVisibleCarType = true;
			},
			// 冻结
			handleFreeze(row) {
				const state = row.state == 1 ? "冻结" : row.state == 2 ? "解冻" : "";
				this.$confirm(`确定${state}吗?`, "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					this.dataFour.carTypes = this.dataFour.carTypes.map((item) => {
						if (item.id === row.id) {
							return {
								...item,
								state: state === "冻结" ? 2 : state == "解冻" ? 1 : "",
							};
						}
						return item;
					});
					this.form.carTypes = this.dataFour.carTypes;
					this.carTypes = this.form.carTypes;
					this.onDataFourHandle();
				});
			},
			// 删除
			handleDelete(row) {
				this.$confirm("确定删除吗?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				}).then(() => {
					this.form.carTypes = this.form.carTypes.filter(
						(item) => item.id !== row.id
					);
					this.carTypes = this.carTypes.filter((item) => item.id !== row.id);
					this.dataFive = this.dataFive.filter((item) => item.carId !== row.id);
					this.onDataFourHandle();
				});
			},
			// 选择车型
			handleSelectData(e) {

				const defaultData = {
					illegalStop: 0,
					packageNameOne: '套餐1',
					packageDurationOne: 0,
					packagePriceOne: 0,
					packageMinAvailablePowerOne: 0,
					packageUseTimeOneStart: '00:00',
					packageUseTimeOneEnd: '00:00',
					packageNameTwo: '套餐2',
					packageDurationTwo: 0,
					packagePriceTwo: 0,
					packageMinAvailablePowerTwo: 0,
					packageUseTimeTwoStart: '00:00',
					packageUseTimeTwoEnd: '00:00',
					packageNameThree: '套餐3',
					packageDurationThree: 0,
					packagePriceThree: 0,
					packageMinAvailablePowerThree: 0,
					packageUseTimeThreeStart: '00:00',
					packageUseTimeThreeEnd: '00:00',
					packageNameFour: '套餐4',
					packageDurationFour: 0,
					packagePriceFour: 0,
					packageMinAvailablePowerFour: 0,
					packageUseTimeFourStart: '00:00',
					packageUseTimeFourEnd: '00:00',
					packageNameFive: '套餐5',
					packageDurationFive: 0,
					packagePriceFive: 0,
					packageMinAvailablePowerFive: 0,
					packageUseTimeFiveStart: '00:00',
					packageUseTimeFiveEnd: '00:00',
				}

				const filterDataFive = this.dataFive?.filter(
					(item) => item?.carId === this?.carId
				);
				if (e?.length >= filterDataFive?.length) {
					this.form.carTypes = e.map((item, index) => {
						const indexs = this.form?.carTypes[index];
						if (indexs?.id === item.id) {
							return {
								...this.form?.carTypes[index],
								carId: indexs?.carId,
								// bufferDistance
								bufferNum: indexs?.bufferDistance,
								orderFirstRemind: indexs?.orderFirstRemind,
								orderTwoRemind: indexs?.orderTwoRemind,
								lowBatteryRemind: indexs?.lowBatteryRemind,
								lowBatteryNoUse: indexs?.lowBatteryNoUse,
								lockTime: indexs?.lockTime,
								workerMoveCar: indexs?.workerMoveCar,
								dayUseTimeSlotStart: indexs?.dayUseTimeSlotStart,
								dayUseTimeSlotEnd: indexs?.dayUseTimeSlotEnd,
								state: indexs.state === 2 ? 2 : 1,
							};
						}
						return {
							...item,
							state: indexs.state === 2 ? 2 : 1,
						};
					});
				} else {
					const carTypes = this.form.carTypes.map((item) => {
						let carTypesList = e.find((items) => items.id === item.id);
						if (carTypesList) {
							return {
								...item,
								carId: item?.carId,
								bufferNum: item?.bufferNum,
								orderFirstRemind: item?.orderFirstRemind,
								orderTwoRemind: item?.orderTwoRemind,
								lowBatteryRemind: item?.lowBatteryRemind,
								lowBatteryNoUse: item?.lowBatteryNoUse,
								lockTime: item?.lockTime,
								workerMoveCar: item?.workerMoveCar,
								dayUseTimeSlotEnd: item?.dayUseTimeSlotEnd,
								state: item.state === 2 ? 2 : 1,
							};
						}
					});
					this.form.carTypes = carTypes.filter((item) => item);
				}

				if (this.form?.carTypes?.length > this.newCarTypes?.length) {
					const newCarTypes = this.form?.carTypes.map((item, index) => {
						if (this?.newCarTypes[index]?.id === item?.id) {
							return {
								...this.newCarTypes[index],
								state: this?.newCarTypes[index]?.state === 2 ? 2 : 1,
							};
						}
						return {
							...item,
							...defaultData
						};
					});
					this.form.carTypes = newCarTypes;
				} else {
					const newCarTypes = this.form?.carTypes?.map((item, index) => {
						//上一次选的如果这次还有保留 
						if (this.newCarTypes && this.newCarTypes.filter((items) => items.id === item.id).length >
							0) {
							return {
								...this.newCarTypes.filter((items) => items.id === item.id)[0]
							}
						}

						return {
							...item,
							state: item.state === 2 ? 2 : 1,
							...defaultData
						};
					});
					this.form.carTypes = [...newCarTypes];
				}
				this.dataFive = [...this.dataFive];
				this.onDataFourHandle();
				this.handleSelectClose();
			},
			handleSelectNewData(e) {
				this.form.carTypes = e;
				this.carTypes = e;
			},
			// 关闭车型弹窗
			handleSelectClose() {
				this.dialogVisibleCarType = false;
			},
			// 选择车辆
			handleSelectDataCarList(e) {
				if (e?.length > 0) {
					this.form.carTypes = this.form.carTypes.map((item) => {
						return {
							...item,
							carId: item.id == this.carId ? e : item.carId,
						};
					});

					const dataFive = e?.map((item) => {
						return {
							...item,
							carId: this.carId,
						};
					});
					
					const newDataFive = this.dataFive.concat(dataFive);
					
					this.dataFive = this.noRepeat(newDataFive);

					// const filterDataFive = this.dataFive?.filter(
					// 	(item) => item?.carId === this?.carId
					// );
					// console.log(
					//   "filterDataFivefilterDataFivefilterDataFive",
					//   filterDataFive,
					//   filterDataFive?.length,
					//   e,
					//   e?.length
					// );
					// console.log('this.carId',this.carId)
					// if (filterDataFive?.length > e?.length) {
					// 	const dataFiveList = this.dataFive.filter(
					// 		(item) => item.carId !== this.carId
					// 	);
					// 	this.dataFive = dataFiveList.concat(e);
					// 	this.dataFive = this.noRepeat(this.dataFive);
					// }
					// console.log('this.dataFive',this.dataFive)
				} else {
					this.form.carTypes = this.form.carTypes.map((item) => {
						return {
							...item,
							carId: item.id == this.carId ? [] : item.carId,
						};
					});
					console.log('this.carTypes',this.carTypes)
					// this.dataFive = this.dataFive.filter(
					// 	(item) => item.carId !== this.carId
					// );
				}
				this.carTypes = this.form.carTypes;
				console.log('this.carTypes',this.carTypes)
				this.carList = [];
				this.carLists = [];
				this.onDataFourHandle();
				this.handleSelectCloseCarList();
			},
			handleSelectNewDataCarList(e) {
				// console.log('handleSelectNewDataCarList',e)
				// this.carLists = e;
			},
			// 关闭车辆弹窗
			handleSelectCloseCarList() {
				this.dialogVisibleCarList = false;
			},
			salaryChange(e, t, max, index) {
				const value = e.target.value;
				if (value) {
					if (isNaN(value * 1)) {
						this.form[t] = "";
						e.target.value = "";
					} else {
						if (Number(value) > Number(max)) {
							console.log(value, '最大值：' + max, '判断');
							if (t === "orderTwoRemind") {
								this.$message.warning(
									"第二次订单倒计时时间不能大于第一次订单倒计时时间"
								);
							}
							this.form[t] = max;
							e.target.value = max;
						} else {
							if (t === "lockTime" && value < 1) {
								this.form.carTypes[index].lockTime = 1;
								e.target.value = 1;
								return;
							}
							this.form[t] = value * 1;
							e.target.value = value * 1;
						}
					}
				} else {
					this.form[t] = "";
					delete this.form[t]
				}

				if (t === "bufferNum") {
					console.log("this.form.carTypes[index]", this.form.carTypes[index]);
					this.form.carTypes[index].bufferNum = e.target.value;
				}
				if (t === "orderFirstRemind") {
					this.form.carTypes[index].orderFirstRemind = e.target.value;
				}
				if (t === "orderTwoRemind") {
					this.form.carTypes[index].orderTwoRemind = e.target.value;
				}
				if (t === "lowBatteryRemind") {
					this.form.carTypes[index].lowBatteryRemind = e.target.value;
				}
				if (t === "lowBatteryNoUse") {
					this.form.carTypes[index].lowBatteryNoUse = e.target.value;
				}
				if (t === "lockTime") {
					this.form.carTypes[index].lockTime = e.target.value;
				}
			},

			noRepeat(arr) {
				for (var i = 0; i < arr?.length; i++) {
					for (var j = 0; j < arr?.length; j++) {
						if (arr[i].id == arr[j].id && i != j) {
							arr.splice(j, 1);
						}
					}
				}
				return arr;
			},

			handleCreateCarList(row) {
				
				console.log('this.carId',row.id)
				this.carId = row.id;
				this.carList = row?.carId;
				this.carLists = this.carList;
				this.dialogVisibleCarList = true;
			},
		},
	};
</script>

<style scoped>
</style>