<template>
  <div class="main-content">
    <h3 class="marginBottom20">{{ $route.query.id ? "编辑" : "新增" }}区域</h3>
    <div class="flex justify-content-space-between">
      <div>
        <el-button @click="getRecover">返回</el-button>
      </div>
      <div>
        <el-button
          size="medium"
          :disabled="submitType"
          type="primary"
          @click="handleSubmit"
          >保存</el-button
        >
        <el-button @click="goBack()">取消</el-button>
      </div>
    </div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="区域信息" name="createOne">
        <div>
          <createOne ref="createOne" @onload="onload" />
        </div>
      </el-tab-pane>
      <el-tab-pane label="区域电子围栏" name="createTwo"> </el-tab-pane>
      <el-tab-pane label="停车区电子围栏" name="createThree"> </el-tab-pane>
      <el-tab-pane label="车型管理" name="createFour">
        <createFour
          :dataFour="dataFour"
          :dataFive="dataFive"
          ref="createFour"
          @onDataFourHandle="onDataFourHandle"
        />
      </el-tab-pane>
      <el-tab-pane label="车辆管理" name="createFive">
        <!-- <createFive
          :dataFour="dataFour"
          :dataFive="dataFive"
          ref="createFive"
          @onDataFourHandle="onDataFourHandle"
        /> -->
      </el-tab-pane>
      <el-tab-pane label="计费设置" name="createSix">
        <createSix ref="createSix" :carTypePackages="carTypePackages" />
      </el-tab-pane>

      <el-tab-pane label="计费规则设置" name="createSeven">
        <div>
          <createSeven ref="createSeven" />
        </div>
      </el-tab-pane>
    </el-tabs>

    <div>
      <div v-if="activeName == 'createTwo'">
        <createTwo
          ref="createTwo"
          @onGeometriesHandle="onGeometriesHandle"
          :geometries="geometries"
          :situation="situation"
          :fuzzyAddress="fuzzyAddress"
          @fuzzyAddressBtn="fuzzyAddressBtn"
          @searchName="searchName"
          :options="options"
        />
      </div>

      <div v-if="activeName == 'createThree'">
        <createThree
          ref="createThree"
          @onGeometriesHandle="onGeometriesHandle"
          :geometries="geometries"
          :situation="situation"
          :fuzzyAddress="fuzzyAddress"
          @fuzzyAddressBtn="fuzzyAddressBtn"
          @searchName="searchName"
          :options="options"
        />
      </div>
      <!-- 
      <div v-if="activeName=='createFour'">
        <createFour
          :dataFour="dataFour"
          :dataFive="dataFive"
          ref="createFour"
          @onDataFourHandle="onDataFourHandle"
        />
      </div>

      <div v-if="activeName=='createFive'">
        <createFive
          :dataFour="dataFour"
          :dataFive="dataFive"
          ref="createFive"
          @onDataFourHandle="onDataFourHandle"
        />
      </div>

      <div v-if="activeName=='createSix'">
        <createSix
          ref="createSix"
          :dataFour="dataFour"
        />
      </div> -->

      <div v-if="activeName == 'createFive'">
        <createFive
          :dataFour="dataFour"
          :dataFive="dataFive"
          ref="createFive"
          @onDataFourHandle="onDataFourHandle"
        />
      </div>
    </div>
  </div>
</template>

<script>
import createOne from "./components/createOne/index.vue";
import createTwo from "./components/createTwo/index.vue";
import createThree from "./components/createThree/index.vue";
import createFour from "./components/createFour/index.vue";
import createFive from "./components/createFive/index.vue";
import createSix from "./components/createSix/index.vue";
import createSeven from "./components/createSeven/index.vue";
import { add, update, view, searchAddress } from "@/api/region/manage";

export default {
  name: "info",
  components: {
    createOne,
    createTwo,
    createThree,
    createFour,
    createFive,
    createSix,
    createSeven,
  },
  data() {
    return {
      id: this.$route.query.id,
      activeName: "createOne",
      formOne: {},
      formSeven: {},
      dataFour: {
        carTypes: [],
      },
      carTypePackages: [],
      dataFive: [],
      submitType: false, // 避免重复点击
      geometries: [
        // 腾讯地图区域的初始值
        // {
        //   id: 1,
        //   paths: [
        //     { lat: 28.159730931106623, lng: 112.99104925198526 },
        //     { lat: 28.155023400813775, lng: 112.99139234751101 },
        //     { lat: 28.155817456440566, lng: 112.999133443544 },
        //     { lat: 28.160222468268145, lng: 112.99836147811993 },
        //   ],
        //   rank: 0,
        //   styleId: "highlight",
        // },
      ],
      situation: [31.348855, 121.498279], //区域围栏默认中心点 经纬度
      // longitude: 31.348855, //区域围栏默认中心点 经度
      // latitude: 121.498279, //区域围栏默认中心点 纬度
      fuzzyAddress: "",
      fuzzyAddressName: "",
      options: [],
    };
  },
  methods: {
    fuzzyAddressBtn(e) {
      this.fuzzyAddress = e;
      this.situation = e.split(",").map(Number);
    },
    async searchName(e) {
      const { data } = await searchAddress({ keyword: e });
      console.log(data, "data");
      this.options = [...data];
    },
    async onload() {
      if (this.id) {
        await this.getView();
      }
    },
    onGeometriesHandle(e) {
      this.geometries = e;
    },
    onDataFourHandle(e) {
      this.dataFour = e.dataFour;
      let carTypes = this.dataFour.carTypes
      console.log('dataFour',this.dataFour)
      //this.carTypePackages = []
      if(!this.carTypePackages){
        this.carTypePackages = []
      }

      if (carTypes && carTypes.length>0) {
        var newCarTypePackages = this.carTypePackages.filter(function(item) {
          if(carTypes.find(element => item.carTypeId === element.id)){
              return true;
          }
          return false; 
        });
        console.log('newCarTypePackages',newCarTypePackages)
        this.carTypePackages = newCarTypePackages;
        for (var i=0;i<carTypes.length;i++) { 
          if(!this.carTypePackages.find(element => element.carTypeId === carTypes[i].id)){
              const defaultData = {
                carTypeId:carTypes[i].id,
                carTypeName:carTypes[i].name,
                packageType:1,
                illegalStop: 0,
                packageNameOne: '套餐1',
                packageDurationOne: 10,
                packagePriceOne: 10,
                packageMinAvailablePowerOne: 10,
                packageUseTimeOneStart: '00:00',
                packageUseTimeOneEnd: '20:30',
                packageNameTwo: '套餐2',
                packageDurationTwo: 0,
                packagePriceTwo: 0,
                packageMinAvailablePowerTwo: 0,
                packageUseTimeTwoStart: '00:00',
                packageUseTimeTwoEnd: '00:00',
                packageNameThree: '套餐3',
                packageDurationThree: 0,
                packagePriceThree: 0,
                packageMinAvailablePowerThree: 0,
                packageUseTimeThreeStart: '00:00',
                packageUseTimeThreeEnd: '00:00',
                packageNameFour: '套餐4',
                packageDurationFour: 0,
                packagePriceFour: 0,
                packageMinAvailablePowerFour: 0,
                packageUseTimeFourStart: '00:00',
                packageUseTimeFourEnd: '00:00',
                packageNameFive: '套餐5',
                packageDurationFive: 0,
                packagePriceFive: 0,
                packageMinAvailablePowerFive: 0,
                packageUseTimeFiveStart: '00:00',
                packageUseTimeFiveEnd: '00:00',
                sort:1
              }
              this.carTypePackages.push(defaultData)
              const defaultData2 = {
                carTypeId:carTypes[i].id,
                carTypeName:carTypes[i].name,
                packageType:2,
                illegalStop: 0,
                packageNameOne: '套餐1',
                packageDurationOne: 10,
                packagePriceOne: 10,
                packageMinAvailablePowerOne: 10,
                packageUseTimeOneStart: '00:00',
                packageUseTimeOneEnd: '20:30',
                packageNameTwo: '套餐2',
                packageDurationTwo: 0,
                packagePriceTwo: 0,
                packageMinAvailablePowerTwo: 0,
                packageUseTimeTwoStart: '00:00',
                packageUseTimeTwoEnd: '00:00',
                packageNameThree: '套餐3',
                packageDurationThree: 0,
                packagePriceThree: 0,
                packageMinAvailablePowerThree: 0,
                packageUseTimeThreeStart: '00:00',
                packageUseTimeThreeEnd: '00:00',
                packageNameFour: '套餐4',
                packageDurationFour: 0,
                packagePriceFour: 0,
                packageMinAvailablePowerFour: 0,
                packageUseTimeFourStart: '00:00',
                packageUseTimeFourEnd: '00:00',
                packageNameFive: '套餐5',
                packageDurationFive: 0,
                packagePriceFive: 0,
                packageMinAvailablePowerFive: 0,
                packageUseTimeFiveStart: '00:00',
                packageUseTimeFiveEnd: '00:00',
                sort:2
              }
              this.carTypePackages.push(defaultData2)
          }
         
        }
      }
   
      this.dataFive = e.dataFive;
    },

    async handleSubmit() {
      const dataOne = await this.$refs.createOne?.form;
      const dataTwo = this.geometries;
      const dataFour = await this.$refs.createFour?.form;
      const dataFive = await this.$refs.createFive?.dataFive;
      const dataSeven = await this.$refs.createSeven?.form;
      let noEmptyReg = /[\S]+/;

      const isPhone = /^1[3456789]\d{9}$/;
      // var isMob = /^((0\d{2,3})-)?(\d{7,8})$/;
      const isMob = /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/;

      if (!noEmptyReg.test(dataOne.name)) {
        this.$message.warning("请完善区域信息的区域名称");
        return;
      }
      if (!noEmptyReg.test(dataOne.regionIntro)) {
        this.$message.warning("请完善区域信息的区域简介");
        return;
      }
      if (!dataOne.state) {
        this.$message.warning("请完善区域信息的区域状态");
        return;
      }
      if (!noEmptyReg.test(dataOne.cityPartner)) {
        this.$message.warning("请完善区域信息的城市合伙人");
        return;
      }
      if (
        !isMob.test(dataOne.partnerPhone) &&
        !isPhone.test(dataOne.partnerPhone)
      ) {
        this.$message.warning("请完善区域信息的合伙人联系方式");
        return;
      }

      if (!noEmptyReg.test(dataOne.cooperWay)) {
        this.$message.warning("请完善区域信息的合作方式");
        return;
      }

      const totalNum =
        Number(dataOne.platformScale) +
        Number(dataOne.betweenScale) +
        Number(dataOne.runScale) +
        Number(dataOne.scenicScale);
      if (Number(totalNum) !== 100) {
        this.$message.warning("请完善区域信息的分成比例");
        return;
      }

      if (dataOne.servicePhones.length === 0) {
        this.$message.warning("请完善区域信息的客服电话");
        return;
      }
      if (!noEmptyReg.test(dataOne.headImage)) {
        this.$message.warning("请完善区域信息的区域头图");
        return;
      }
      if (dataOne.banner.length === 0) {
        this.$message.warning("请完善区域信息的区域banner");
        return;
      }
      if (
        !noEmptyReg.test(dataOne.regionDesc) ||
        dataOne.regionDesc == "<p><br/></p>"
      ) {
        this.$message.warning("请完善区域信息的区域介绍");
        return;
      }
      const highlightItem = dataTwo.find(
        (item) => item.styleId === "highlight"
      );
      const highlightRedItem = dataTwo.find(
        (item) => item.styleId === "highlightRed"
      );
      if (!highlightItem) {
        this.$message.warning("请先绘制区域电子围栏");
        return;
      }
      if (!highlightRedItem) {
        this.$message.warning("请先绘制停车区围栏");
        return;
      }

      let isFourContent = false;
      let isFiveContent = false;
           
  
      if (dataFour?.carTypes?.length > 0) {
        const isData = dataFour?.carTypes?.map((item) => {
          if (
            item?.bufferNum?.toString() &&
            item?.orderFirstRemind?.toString() &&
            item?.orderTwoRemind?.toString() &&
            item?.lowBatteryRemind?.toString() &&
            item?.lowBatteryNoUse?.toString() &&
            item?.lockTime?.toString() &&
            item?.workerMoveCar?.toString() &&
            item?.dayUseTimeSlotStart &&
            item?.dayUseTimeSlotEnd
          ) {
            isFourContent = false;
          } else {
            isFourContent = true;
          }
        });
      }    
      console.log("dataFour.carTypes", dataFour?.carTypes);
      if (isFourContent) {
        this.$message.warning("请填写完整车型管理");
        return;
      }

      const carTypePackages = await this.$refs.createSix?.carTypePackages;
      console.log('sasa',carTypePackages)
      if (carTypePackages?.length > 0) {
        const isData = carTypePackages?.map((item) => {
          if (
            item?.illegalStop?.toString() &&
            item?.packageNameOne?.toString() &&
            item?.packageDurationOne?.toString() &&
            item?.packagePriceOne?.toString() &&
            item?.packageMinAvailablePowerOne?.toString() &&
            item?.packageUseTimeOneStart &&
            item?.packageUseTimeOneEnd &&
            item?.packageNameTwo?.toString() &&
            item?.packageDurationTwo?.toString() &&
            item?.packagePriceTwo?.toString() &&
            item?.packageMinAvailablePowerTwo?.toString() &&
            item?.packageUseTimeTwoStart &&
            item?.packageUseTimeTwoEnd &&
            item?.packageNameThree?.toString() &&
            item?.packageDurationThree?.toString() &&
            item?.packagePriceThree?.toString() &&
            item?.packageMinAvailablePowerThree?.toString() &&
            item?.packageUseTimeThreeStart &&
            item?.packageUseTimeThreeEnd &&
            item?.packageNameFour?.toString() &&
            item?.packageDurationFour?.toString() &&
            item?.packagePriceFour?.toString() &&
            item?.packageMinAvailablePowerFour?.toString() &&
            item?.packageUseTimeFourStart &&
            item?.packageUseTimeFourEnd &&
            item?.packageNameFive?.toString() &&
            item?.packageDurationFive?.toString() &&
            item?.packagePriceFive?.toString() &&
            item?.packageMinAvailablePowerFive?.toString() &&
            item?.packageUseTimeFiveStart &&
            item?.packageUseTimeFiveEnd
          ) {
            isFiveContent = false;
          } else {
            console.log('sasa1',item)
            isFiveContent = true;
          }
          return item;
        });
      }
      
      if (isFiveContent) {
        this.$message.warning("请填写完整计费设置");
        return;
      }

      if (
        !noEmptyReg.test(dataSeven.regionRule) ||
        dataSeven.regionRule == "<p><br/></p>"
      ) {
        this.$message.warning("请完善计费规则设置");
        return;
      }

      const carTypes = dataFour.carTypes.map((item) => {
        return {
          ...item,
          speedType: item.gearSpeed,
          carNum: item.carId ? item.carId.length : 0,
          carTypeId: item.id,
          state: item?.state,
          dayUseTimeSlotEnd: item.dayUseTimeSlotEnd + ":00",
          dayUseTimeSlotStart: item.dayUseTimeSlotStart + ":59",
          packageUseTimeOne: `${item.packageUseTimeOneStart}:00~${item.packageUseTimeOneEnd}:59`,
          packageUseTimeTwo: `${item.packageUseTimeTwoStart}:00~${item.packageUseTimeTwoEnd}:59`,
          packageUseTimeThree: `${item.packageUseTimeThreeStart}:00~${item.packageUseTimeThreeEnd}:59`,
          packageUseTimeFour: `${item.packageUseTimeFourStart}:00~${item.packageUseTimeFourEnd}:59`,
          packageUseTimeFive: `${item.packageUseTimeFiveStart}:00~${item.packageUseTimeFiveEnd}:59`,
        };
      });

      const carTypePackages2 = carTypePackages.map((item) => {
        return {
          ...item,
          regionId:this.id,
          packageUseTimeOne: `${item.packageUseTimeOneStart}:00~${item.packageUseTimeOneEnd}:59`,
          packageUseTimeTwo: `${item.packageUseTimeTwoStart}:00~${item.packageUseTimeTwoEnd}:59`,
          packageUseTimeThree: `${item.packageUseTimeThreeStart}:00~${item.packageUseTimeThreeEnd}:59`,
          packageUseTimeFour: `${item.packageUseTimeFourStart}:00~${item.packageUseTimeFourEnd}:59`,
          packageUseTimeFive: `${item.packageUseTimeFiveStart}:00~${item.packageUseTimeFiveEnd}:59`,
        };
      });

      const data = {
        ...dataOne,
        ...dataSeven,
        carIds: this.dataFive.map((item) => item.id),
        carTypes,
        carTypePackages:carTypePackages2,
        servicePhones: dataOne.servicePhones.join(","),
        banner: dataOne.banner.join(","),
        fences: dataTwo
          .filter((item) => item.styleId === "highlight")[0]
          .paths.map((item) => {
            return {
              latitude: item.lat,
              longitude: item.lng,
            };
          }),
        fenceParks: dataTwo
          .filter((item) => item.styleId === "highlightRed")
          .map((item) => {
            return {
              lists: item.paths.map((items) => {
                return {
                  latitude: items.lat,
                  longitude: items.lng,
                };
              }),
            };
          }),
      };
      console.log(data)
      if (this.id) {
        update({ ...data, id: this.id }).then((res) => {
          if (res.code === 200) {
            this.$message.success("编辑成功");
            this.geometries = [];
            this.$router.go(-1);
          } else {
            this.$message.error(res.msg);
            this.submitType = false;
          }
        });
      } else {
        add(data).then((res) => {
          if (res.code === 200) {
            this.$message.success("添加成功");
            this.geometries = [];
            this.$router.go(-1);
          } else {
            this.$message.error(res.msg);
            this.submitType = false;
          }
        });
      }
    },
    async getView() {
      const { data } = await view({ id: this.id });
      this.$refs.createOne.form = {
        ...data,
        banner: data.banner.split(","),
        totalNum:
          Number(data.platformScale) +
          Number(data.betweenScale) +
          Number(data.runScale) +
          Number(data.scenicScale),
      };

      //区域中心点 赋值
      this.situation = [data.latitude, data.longitude];

      this.$refs.createSeven.form.regionRule = data.regionRule;

      const highlightList = data.fences.map((item) => {
        return {
          lat: item.latitude,
          lng: item.longitude,
        };
      });

      const highlightData = [
        {
          id: 1,
          paths: highlightList,
          rank: 0,
          styleId: "highlight",
        },
      ];

      const highlightRedData = data.fenceParks.map((item) => {
        const id = 1;
        const rank = 0;
        return {
          id: id + 1,
          paths: item.list.map((items) => {
            return {
              lat: items.latitude,
              lng: items.longitude,
            };
          }),
          rank: rank + 1,
          styleId: "highlightRed",
        };
      });

      this.dataFive = data.cars.map((item) => {
        return {
          ...item,
          id: item.carId,
          carId: item.carTypeId,
          typeName: item.name,
        };
      });
      this.geometries = [...highlightData, ...highlightRedData];
      this.carTypePackages = data.carTypePackages.map((item, index) => {
        return {
          ...item,
          packageUseTimeOneStart: item.packageUseTimeOne.slice(0, 5),
          packageUseTimeOneEnd: item.packageUseTimeOne.slice(9, 14),
          packageUseTimeTwoStart: item.packageUseTimeTwo.slice(0, 5),
          packageUseTimeTwoEnd: item.packageUseTimeTwo.slice(9, 14),
          packageUseTimeThreeStart: item.packageUseTimeThree.slice(0, 5),
          packageUseTimeThreeEnd: item.packageUseTimeThree.slice(9, 14),
          packageUseTimeFourStart: item.packageUseTimeFour.slice(0, 5),
          packageUseTimeFourEnd: item.packageUseTimeFour.slice(9, 14),
          packageUseTimeFiveStart: item.packageUseTimeFive.slice(0, 5),
          packageUseTimeFiveEnd: item.packageUseTimeFive.slice(9, 14),
          // dayUseTimeSlotStart: item.dayUseTimeSlotStart.slice(0, 5),
          // dayUseTimeSlotEnd: item.dayUseTimeSlotEnd.slice(0, 5),
        };
      });
      this.dataFour.carTypes = data.carTypes.map((item, index) => {
        let carIds = this.dataFive.filter((items) => items.carId !== item.id);
        let carId = [];
        carIds.map((items) => {
          console.log(item.carTypeId == items.carTypeId);
          if (item.carTypeId == items.carTypeId) {
            carId.push(items);
          }
        });
    
        return {
          ...item,
          carId: [],
          id: item.carTypeId,
          speedType: item.speedType.toString(),
          gearSpeed: item.speedType.toString(),
          lowGearSpeed: item.speedNum,
          highGearSpeed: item.speedNum,
          packageUseTimeOneStart: item.packageUseTimeOne.slice(0, 5),
          packageUseTimeOneEnd: item.packageUseTimeOne.slice(9, 14),
          packageUseTimeTwoStart: item.packageUseTimeTwo.slice(0, 5),
          packageUseTimeTwoEnd: item.packageUseTimeTwo.slice(9, 14),
          packageUseTimeThreeStart: item.packageUseTimeThree.slice(0, 5),
          packageUseTimeThreeEnd: item.packageUseTimeThree.slice(9, 14),
          packageUseTimeFourStart: item.packageUseTimeFour.slice(0, 5),
          packageUseTimeFourEnd: item.packageUseTimeFour.slice(9, 14),
          packageUseTimeFiveStart: item.packageUseTimeFive.slice(0, 5),
          packageUseTimeFiveEnd: item.packageUseTimeFive.slice(9, 14),
          dayUseTimeSlotStart: item.dayUseTimeSlotStart.slice(0, 5),
          dayUseTimeSlotEnd: item.dayUseTimeSlotEnd.slice(0, 5),
        };
      });
      
      console.log(this.dataFour.carTypes, "最后的结果");
      this.$nextTick(() => {
        this.$refs.createFour.form.carTypes = this.dataFour.carTypes;
      });
      // this.$refs.createFour.form.carTypes = this.dataFour.carTypes;
    },
    goBack() {
      this.$router.go(-1);
    },
    getRecover() {
      this.$confirm("是否保存?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.handleSubmit();
        })
        .catch(() => {
          this.goBack();
        });
    },
  },
};
</script>

<style scoped>
</style>