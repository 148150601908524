<template>
  <div>
    <h3>套餐设置</h3>
    <avue-form ref="form" v-model="form" :option="formOption">
      <template slot="carTypes">
        <div class="main-content">
          <avue-crud
            :data="carTypePackages"
            :option="option"
            :table-loading="showLoading"
          >
            <template slot="illegalStop" slot-scope="{ row }">
              <el-input
                v-model="row.illegalStop"
                :value="row.illegalStop"
                placeholder="请输入"
                onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                @blur="
                  salaryDianChange(
                    $event,
                    'illegalStop',
                    row.depositPrice,
                    row.$index
                  )
                "
                type="text"
                clearable
              />
            </template>

            <template slot="packageNameOne" slot-scope="{ row }">
              <el-input
                v-model="row.packageNameOne"
                :value="row.packageNameOne"
                placeholder="请输入"
                type="text"
                clearable
                maxlength="20"
              />
            </template>

            <template slot="packageDurationOne" slot-scope="{ row }">
              <el-input
                v-model="row.packageDurationOne"
                :value="row.packageDurationOne"
                placeholder="请输入"
                onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
                @blur="
                  salaryChange($event, 'packageDurationOne', 999999, row.$index)
                "
                type="text"
                clearable
              />
            </template>

            <template slot="packagePriceOne" slot-scope="{ row }">
              <el-input-number
                :controls="false"
                v-model="row.packagePriceOne"
                :value="row.packagePriceOne"
                placeholder="请输入"
                onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                @blur="
                  salaryDianChange(
                    $event,
                    'packagePriceOne',
                    999999.99,
                    row.$index
                  )
                "
                type="text"
                clearable
              />
            </template>

            <template slot="packageMinAvailablePowerOne" slot-scope="{ row }">
              <el-input
                v-model="row.packageMinAvailablePowerOne"
                :value="row.packageMinAvailablePowerOne"
                placeholder="请输入"
                onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
                @blur="
                  salaryChange(
                    $event,
                    'packageMinAvailablePowerOne',
                    100,
                    row.$index
                  )
                "
                type="text"
                clearable
              />
            </template>

            <template slot="packageUseTimeOne" slot-scope="{ row }">
              <div class="flex justify-content-center">
                <div class="width100">
                  <el-time-select
                    class="width100"
                    placeholder="起始时间"
                    v-model="row.packageUseTimeOneStart"
                    :value="row.packageUseTimeOneStart"
                    :picker-options="{
                      start: '00:00',
                      step: '00:01',
                      end: '23:58',
                      maxTime: row.packageUseTimeOneEnd,
                    }"
                  >
                  </el-time-select>
                </div>

                <div style="margin: 0 10px">-</div>
                <div class="width100">
                  <el-time-select
                    class="width100"
                    placeholder="结束时间"
                    v-model="row.packageUseTimeOneEnd"
                    :value="row.packageUseTimeOneEnd"
                    :picker-options="{
                      start: '00:00',
                      step: '00:01',
                      end: '23:59',
                      minTime: row.packageUseTimeOneStart,
                    }"
                  >
                  </el-time-select>
                </div>
              </div>
            </template>

            <template slot="packageNameTwo" slot-scope="{ row }">
              <el-input
                v-model="row.packageNameTwo"
                :value="row.packageNameTwo"
                placeholder="请输入"
                type="text"
                clearable
                maxlength="20"
              />
            </template>

            <template slot="packageDurationTwo" slot-scope="{ row }">
              <el-input
                v-model="row.packageDurationTwo"
                :value="row.packageDurationTwo"
                placeholder="请输入"
                onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
                @blur="
                  salaryChange($event, 'packageDurationTwo', 999999, row.$index)
                "
                type="text"
                clearable
              />
            </template>

            <template slot="packagePriceTwo" slot-scope="{ row }">
              <el-input-number
                :controls="false"
                v-model="row.packagePriceTwo"
                :value="row.packagePriceTwo"
                placeholder="请输入"
                onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                @blur="
                  salaryDianChange(
                    $event,
                    'packagePriceTwo',
                    999999.99,
                    row.$index
                  )
                "
                type=""
                clearable
              />
            </template>

            <template slot="packageMinAvailablePowerTwo" slot-scope="{ row }">
              <el-input
                v-model="row.packageMinAvailablePowerTwo"
                :value="row.packageMinAvailablePowerTwo"
                placeholder="请输入"
                onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
                @blur="
                  salaryChange(
                    $event,
                    'packageMinAvailablePowerTwo',
                    100,
                    row.$index
                  )
                "
                type="text"
                clearable
              />
            </template>

            <template slot="packageUseTimeTwo" slot-scope="{ row }">
              <div class="flex justify-content-center">
                <div class="width100">
                  <el-time-select
                    class="width100"
                    placeholder="起始时间"
                    v-model="row.packageUseTimeTwoStart"
                    :value="row.packageUseTimeTwoStart"
                    :picker-options="{
                      start: '00:00',
                      step: '00:01',
                      end: '23:58',
                      maxTime: row.packageUseTimeTwoEnd,
                    }"
                  >
                  </el-time-select>
                </div>

                <div style="margin: 0 10px">-</div>
                <div class="width100">
                  <el-time-select
                    class="width100"
                    placeholder="结束时间"
                    v-model="row.packageUseTimeTwoEnd"
                    :value="row.packageUseTimeTwoEnd"
                    :picker-options="{
                      start: '00:00',
                      step: '00:01',
                      end: '23:59',
                      minTime: row.packageUseTimeTwoStart,
                    }"
                  >
                  </el-time-select>
                </div>
              </div>
            </template>

            <template slot="packageNameThree" slot-scope="{ row }">
              <el-input
                v-model="row.packageNameThree"
                :value="row.packageNameThree"
                placeholder="请输入"
                type="text"
                clearable
                maxlength="20"
              />
            </template>

            <template slot="packageDurationThree" slot-scope="{ row }">
              <el-input
                v-model="row.packageDurationThree"
                :value="row.packageDurationThree"
                placeholder="请输入"
                onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
                @blur="
                  salaryChange(
                    $event,
                    'packageDurationThree',
                    999999,
                    row.$index
                  )
                "
                type="text"
                clearable
              />
            </template>

            <template slot="packagePriceThree" slot-scope="{ row }">
              <el-input-number
                :controls="false"
                v-model="row.packagePriceThree"
                :value="row.packagePriceThree"
                placeholder="请输入"
                onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                @blur="
                  salaryDianChange(
                    $event,
                    'packagePriceThree',
                    999999.99,
                    row.$index
                  )
                "
                type="text"
                clearable
              />
            </template>

            <template slot="packageMinAvailablePowerThree" slot-scope="{ row }">
              <el-input
                v-model="row.packageMinAvailablePowerThree"
                :value="row.packageMinAvailablePowerThree"
                placeholder="请输入"
                onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
                @blur="
                  salaryChange(
                    $event,
                    'packageMinAvailablePowerThree',
                    100,
                    row.$index
                  )
                "
                type="text"
                clearable
              />
            </template>

            <template slot="packageUseTimeThree" slot-scope="{ row }">
              <div class="flex justify-content-center">
                <div class="width100">
                  <el-time-select
                    class="width100"
                    placeholder="起始时间"
                    v-model="row.packageUseTimeThreeStart"
                    :value="row.packageUseTimeThreeStart"
                    :picker-options="{
                      start: '00:00',
                      step: '00:01',
                      end: '23:58',
                      maxTime: row.packageUseTimeThreeEnd,
                    }"
                  >
                  </el-time-select>
                </div>

                <div style="margin: 0 10px">-</div>
                <div class="width100">
                  <el-time-select
                    class="width100"
                    placeholder="结束时间"
                    v-model="row.packageUseTimeThreeEnd"
                    :value="row.packageUseTimeThreeEnd"
                    :picker-options="{
                      start: '00:00',
                      step: '00:01',
                      end: '23:59',
                      minTime: row.packageUseTimeThreeStart,
                    }"
                  >
                  </el-time-select>
                </div>
              </div>
            </template>

            <template slot="packageNameFour" slot-scope="{ row }">
              <el-input
                v-model="row.packageNameFour"
                :value="row.packageNameFour"
                placeholder="请输入"
                type="text"
                clearable
                maxlength="20"
              />
            </template>

            <template slot="packageDurationFour" slot-scope="{ row }">
              <el-input
                v-model="row.packageDurationFour"
                :value="row.packageDurationFour"
                placeholder="请输入"
                onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
                @blur="
                  salaryChange(
                    $event,
                    'packageDurationFour',
                    999999,
                    row.$index
                  )
                "
                type="text"
                clearable
              />
            </template>

            <template slot="packagePriceFour" slot-scope="{ row }">
              <el-input-number
                :controls="false"
                v-model="row.packagePriceFour"
                :value="row.packagePriceFour"
                placeholder="请输入"
                onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                @blur="
                  salaryDianChange(
                    $event,
                    'packagePriceFour',
                    999999.99,
                    row.$index
                  )
                "
                type="text"
                clearable
              />
            </template>

            <template slot="packageMinAvailablePowerFour" slot-scope="{ row }">
              <el-input
                v-model="row.packageMinAvailablePowerFour"
                :value="row.packageMinAvailablePowerFour"
                placeholder="请输入"
                onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
                @blur="
                  salaryChange(
                    $event,
                    'packageMinAvailablePowerFour',
                    100,
                    row.$index
                  )
                "
                type="text"
                clearable
              />
            </template>

            <template slot="packageUseTimeFour" slot-scope="{ row }">
              <div class="flex justify-content-center">
                <div class="width100">
                  <el-time-select
                    class="width100"
                    placeholder="起始时间"
                    v-model="row.packageUseTimeFourStart"
                    :value="row.packageUseTimeFourStart"
                    :picker-options="{
                      start: '00:00',
                      step: '00:01',
                      end: '23:58',
                      maxTime: row.packageUseTimeFourEnd,
                    }"
                  >
                  </el-time-select>
                </div>

                <div style="margin: 0 10px">-</div>
                <div class="width100">
                  <el-time-select
                    class="width100"
                    placeholder="结束时间"
                    v-model="row.packageUseTimeFourEnd"
                    :value="row.packageUseTimeFourEnd"
                    :picker-options="{
                      start: '00:00',
                      step: '00:01',
                      end: '23:59',
                      minTime: row.packageUseTimeFourStart,
                    }"
                  >
                  </el-time-select>
                </div>
              </div>
            </template>

            <template slot="packageNameFive" slot-scope="{ row }">
              <el-input
                v-model="row.packageNameFive"
                :value="row.packageNameFive"
                placeholder="请输入"
                type="text"
                clearable
                maxlength="20"
              />
            </template>

            <template slot="packageDurationFive" slot-scope="{ row }">
              <el-input
                v-model="row.packageDurationFive"
                :value="row.packageDurationFive"
                placeholder="请输入"
                onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
                @blur="
                  salaryChange(
                    $event,
                    'packageDurationFive',
                    999999,
                    row.$index
                  )
                "
                type="text"
                clearable
              />
            </template>

            <template slot="packagePriceFive" slot-scope="{ row }">
              <el-input-number
                :controls="false"
                v-model="row.packagePriceFive"
                :value="row.packagePriceFive"
                placeholder="请输入"
                onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)"
                @blur="
                  salaryDianChange(
                    $event,
                    'packagePriceFive',
                    999999.99,
                    row.$index
                  )
                "
                type="text"
                clearable
              />
            </template>

            <template slot="packageMinAvailablePowerFive" slot-scope="{ row }">
              <el-input
                v-model="row.packageMinAvailablePowerFive"
                :value="row.packageMinAvailablePowerFive"
                placeholder="请输入"
                onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
                @blur="
                  salaryChange(
                    $event,
                    'packageMinAvailablePowerFive',
                    100,
                    row.$index
                  )
                "
                type="text"
                clearable
              />
            </template>

            <template slot="packageUseTimeFive" slot-scope="{ row }">
              <div class="flex justify-content-center">
                <div class="width100">
                  <el-time-select
                    class="width100"
                    placeholder="起始时间"
                    v-model="row.packageUseTimeFiveStart"
                    :value="row.packageUseTimeFiveStart"
                    :picker-options="{
                      start: '00:00',
                      step: '00:01',
                      end: '23:58',
                      maxTime: row.packageUseTimeFiveEnd,
                    }"
                  >
                  </el-time-select>
                </div>

                <div style="margin: 0 10px">-</div>
                <div class="width100">
                  <el-time-select
                    class="width100"
                    placeholder="结束时间"
                    v-model="row.packageUseTimeFiveEnd"
                    :value="row.packageUseTimeFiveEnd"
                    :picker-options="{
                      start: '00:00',
                      step: '00:01',
                      end: '23:59',
                      minTime: row.packageUseTimeFiveStart,
                    }"
                  >
                  </el-time-select>
                </div>
              </div>
            </template>
          </avue-crud>
        </div>
      </template>
    </avue-form>
  </div>
</template>

<script>
export default {
  name: "index",
  props: {
    carTypePackages: {
      type: Array,
      default:[],
    },
  },
  data() {
    return {
      tableData: [],
      showLoading: false,
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 0,
        column: [
          {
            label: "",
            prop: "carTypes",
            maxlength: 20,
            showWordLimit: true,
            span: 24,
            row: true,
            // rules: [
            //   {
            //     required: true,
            //     message: "请选择车辆",
            //     trigger: "blur",
            //   },
            // ],
          },
        ],
      },
      option: {
        width: 20000,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: false, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        menu: false,
        column: [
          {
            label: "车型名称",
            prop: "carTypeName",
            width: 140,
          },
          {
            label: "套餐类型",
            prop: "packageType",
            width: 80,
            type: "select",
            dicData: [
              {
                label: "普通订单",
                value: 1,
              },
              {
                label: "续费订单",
                value: 2,
              },
            ],
          },
          {
            label: "违停费（元）",
            prop: "illegalStop",
            width: 160,
          },
          {
            label: "套餐1名称",
            prop: "packageNameOne",
            width: 200,
          },
          {
            label: "套餐1时长（分钟）",
            prop: "packageDurationOne",
            width: 160,
          },
          {
            label: "套餐1收费（元）",
            prop: "packagePriceOne",
            width: 160,
          },
          {
            label: "套餐1最低可用电量（%）",
            prop: "packageMinAvailablePowerOne",
            width: 160,
          },
          {
            label: "套餐1可使用时间",
            prop: "packageUseTimeOne",
            width: 300,
          },
          {
            label: "套餐2名称",
            prop: "packageNameTwo",
            width: 200,
          },
          {
            label: "套餐2时长（分钟）",
            prop: "packageDurationTwo",
            width: 160,
          },
          {
            label: "套餐2收费（元）",
            prop: "packagePriceTwo",
            width: 160,
          },
          {
            label: "套餐2最低可用电量（%）",
            prop: "packageMinAvailablePowerTwo",
            width: 160,
          },
          {
            label: "套餐2可使用时间",
            prop: "packageUseTimeTwo",
            width: 300,
          },
          {
            label: "套餐3名称",
            prop: "packageNameThree",
            width: 200,
          },
          {
            label: "套餐3时长（分钟）",
            prop: "packageDurationThree",
            width: 160,
          },
          {
            label: "套餐3收费（元）",
            prop: "packagePriceThree",
            width: 160,
          },
          {
            label: "套餐3最低可用电量（%）",
            prop: "packageMinAvailablePowerThree",
            width: 160,
          },
          {
            label: "套餐3可使用时间",
            prop: "packageUseTimeThree",
            width: 300,
          },
          {
            label: "套餐4名称",
            prop: "packageNameFour",
            width: 200,
          },
          {
            label: "套餐4时长（分钟）",
            prop: "packageDurationFour",
            width: 160,
          },
          {
            label: "套餐4收费（元）",
            prop: "packagePriceFour",
            width: 160,
          },
          {
            label: "套餐4最低可用电量（%）",
            prop: "packageMinAvailablePowerFour",
            width: 160,
          },
          {
            label: "套餐4可使用时间",
            prop: "packageUseTimeFour",
            width: 300,
          },
          {
            label: "套餐5名称",
            prop: "packageNameFive",
            width: 200,
          },
          {
            label: "套餐5时长（分钟）",
            prop: "packageDurationFive",
            width: 160,
          },
          {
            label: "套餐5收费（元）",
            prop: "packagePriceFive",
            width: 160,
          },
          {
            label: "套餐5最低可用电量（%）",
            prop: "packageMinAvailablePowerFive",
            width: 160,
          },
          {
            label: "套餐5可使用时间",
            prop: "packageUseTimeFive",
            width: 300,
          },
        ],
      },
      form: {
        carTypePackages: [],
      },
    };
  },
  methods: {
    salaryChange(e, t, max, index) {
      const value = e.target.value;
      if (value) {
        if (Number(value) > Number(max)) {
          this.form[t] = max;
          e.target.value = max;
        } else {
          // if (t === "packageDurationOne" && value < 3) {
          //   // this.dataFour.carTypes[index].packageDurationOne = 3;
          //   // e.target.value = 3;
          //   return;
          // }
          this.form[t] = value * 1;
          e.target.value = value * 1;
        }
        // if (isNaN(value * 1)) {
        //   // this.form[t] = "";
        //   // e.target.value = "";
        // } else {
        //   if (Number(value) > Number(max)) {
        //     this.form[t] = max;
        //     e.target.value = max;
        //   } else {
        //     // if (t === "packageDurationOne" && value < 3) {
        //     //   // this.dataFour.carTypes[index].packageDurationOne = 3;
        //     //   // e.target.value = 3;
        //     //   return;
        //     // }
        //     this.form[t] = value * 1;
        //     e.target.value = value * 1;
        //   }
        // }
      } else {
        this.form[t] = "";
        delete this.form[t];
        delete this.carTypePackages[index][t];
      }

      if (t === "packageDurationOne") {
        if (!e.target.value == "") {
          this.carTypePackages[index].packageDurationOne = e.target.value;
        } else {
          delete this.carTypePackages[index].packageDurationOne;
        }
      }
      if (t === "packageMinAvailablePowerOne") {
        if (!e.target.value == "") {
          this.carTypePackages[index].packageMinAvailablePowerOne =
            e.target.value;
        } else {
          delete this.carTypePackages[index].packageMinAvailablePowerOne;
        }
      }
      if (t === "packageDurationTwo") {
        if (!e.target.value == "") {
          this.carTypePackages[index].packageDurationTwo = e.target.value;
        } else {
          delete this.carTypePackages[index].packageDurationTwo;
        }
      }
      if (t === "packageMinAvailablePowerTwo") {
        if (!e.target.value == "") {
          this.carTypePackages[index].packageMinAvailablePowerTwo =
            e.target.value;
        } else {
          delete this.carTypePackages[index].packageMinAvailablePowerTwo;
        }
      }
      if (t === "packageDurationThree") {
        if (!e.target.value == "") {
          this.carTypePackages[index].packageDurationThree = e.target.value;
        } else {
          delete this.carTypePackages[index].packageDurationThree;
        }
      }
      if (t === "packageMinAvailablePowerThree") {
        if (!e.target.value == "") {
          this.carTypePackages[index].packageMinAvailablePowerThree =
            e.target.value;
        } else {
          delete this.carTypePackages[index].packageMinAvailablePowerThree;
        }
      }
      if (t === "packageDurationFour") {
        if (!e.target.value == "") {
          this.carTypePackages[index].packageDurationFour = e.target.value;
        } else {
          delete this.carTypePackages[index].packageDurationFour;
        }
      }
      if (t === "packageMinAvailablePowerFour") {
        if (!e.target.value == "") {
          this.carTypePackages[index].packageMinAvailablePowerFour =
            e.target.value;
        } else {
          delete this.carTypePackages[index].packageMinAvailablePowerFour;
        }
      }
      if (t === "packageDurationFive") {
        if (!e.target.value == "") {
          this.carTypePackages[index].packageDurationFive = e.target.value;
        } else {
          delete this.carTypePackages[index].packageDurationFive;
        }
      }
      if (t === "packageMinAvailablePowerFive") {
        if (!e.target.value == "") {
          this.carTypePackages[index].packageMinAvailablePowerFive =
            e.target.value;
        } else {
          delete this.carTypePackages[index].packageMinAvailablePowerFive;
        }
      }
    },

    salaryDianChange(e, t, max, index) {
      const value = e.target.value;
      if (value) {
        if (isNaN(value * 1)) {
          this.form[t] = "";
          e.target.value = "";
        } else {
          if (value > max) {
            if (t === "illegalStop") {
              this.$message.warning(
                `违停费不能大于押金，最大可为${max.toFixed(2)}`
              );
            }
            this.form[t] = max.toFixed(2);
            e.target.value = max.toFixed(2);
          } else {
            this.form[t] = parseFloat(value).toFixed(2);
            e.target.value = parseFloat(value).toFixed(2);
          }
        }
      } else {
        this.form[t] = "";
        delete this.form[t];
      }
      if (t === "illegalStop") {
        if (!e.target.value == "") {
          this.carTypePackages[index].illegalStop = e.target.value;
        } else {
          delete this.carTypePackages[index].illegalStop;
        }
      }
      if (t === "packagePriceOne") {
        if (!e.target.value == "") {
          this.carTypePackages[index].packagePriceOne = e.target.value;
        } else {
          delete this.carTypePackages[index].packagePriceOne;
        }
      }
      if (t === "packagePriceTwo") {
        if (!e.target.value == "") {
          this.carTypePackages[index].packagePriceTwo = e.target.value;
        } else {
          delete this.carTypePackages[index].packagePriceTwo;
        }
      }
      if (t === "packagePriceThree") {
        if (!e.target.value == "") {
          this.carTypePackages[index].packagePriceThree = e.target.value;
        } else {
          delete this.carTypePackages[index].packagePriceThree;
        }
      }
      if (t === "packagePriceFour") {
        if (!e.target.value == "") {
          this.carTypePackages[index].packagePriceFour = e.target.value;
        } else {
          delete this.carTypePackages[index].packagePriceFour;
        }
      }
      if (t === "packagePriceFive") {
        if (!e.target.value == "") {
          this.carTypePackages[index].packagePriceFive = e.target.value;
        } else {
          delete this.carTypePackages[index].packagePriceFive;
        }
      }
    },
  },
};
</script>

<style scoped>
/deep/.el-input-number--small .el-input-number__decrease,
.el-input-number--small .el-input-number__increase {
  display: block;
}
</style>